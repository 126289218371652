import 'intersection-observer'

export default class HeaderLogoResizer {
  
  constructor () {

    // instance variables
    this.isInitialized = false

    // retreive and cache DOM references
    this.headerScrollTracker = document.getElementById('header-scroll-tracker')
    this.homeLinkContainer = document.getElementById('home-link-container')
    this.avatarImage = this.homeLinkContainer.querySelector('.avatar-image')
    this.titleText = this.homeLinkContainer.querySelector('.home-link')
    
    // configure intersection observer with multiple thresholds
    //  - bind this as calling context to callback function
    const observer = new IntersectionObserver(this.handleObserverIntersection.bind(this), {
      threshold: [0, 0.25, 0.5, 0.75, 1]
    });

    observer.observe(this.headerScrollTracker)

  }

  handleObserverIntersection(entries) {
    
    entries.forEach(entry => {
      
      // entry.intersection ratio decreases from 
      //  1 to 0 when scrolling down through 
      //  #header-scroll-tracker div
      
      // convert intersectionRatio into shrinkage
      //  amount so that 0 = 60% original size
      const newAvatarSize = this.roundToTenth( 100 - ((1 - entry.intersectionRatio) * 40) )
      
      // set new size to avatar image
      this.avatarImage.style.width = newAvatarSize + '%'
      this.avatarImage.style.height = newAvatarSize + '%'

      // set margin-left on title text
      this.titleText.style.marginLeft = (.35 * (newAvatarSize) + 27) + 'px'

      // initialize logo based on page load position (i.e. top of page vs mid-page)
      if (!this.isInitialized) {
        if (entry.intersectionRatio > 0) {
          // top of page - scroll tracker visible in browser viewport
          this.homeLinkContainer.classList.add('logo-initialized')
        } else {
           // mid page - scroll tracker out of view above top of browser
           this.homeLinkContainer.style.opacity = "1"
           setTimeout(() => { this.homeLinkContainer.classList.add('logo-initialized') }, 250)
        }
        this.isInitialized = true
      }

    })

  }

  roundToTenth(number){
    return Math.ceil(number / 10) * 10;
  }

}
