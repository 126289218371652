export default class LeadCaptureForm {
  
  constructor () {
   
    // DOM elements
    this.formElem = document.querySelector('#lead-capture-form form')
    this.loadingElem = document.getElementById('elp-loading-image')
    this.formMessage = document.querySelector('#lead-capture-form .elp_form_message')

    // calling context
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleSubmitResponse = this.handleSubmitResponse.bind(this)
    this.toggleLoadingVisibility = this.toggleLoadingVisibility.bind(this)
    this.displayFormError = this.displayFormError.bind(this)

    // event handlers
    this.formElem.addEventListener('submit', this.handleFormSubmit)

  }

  handleFormSubmit(e) {
    
    e.preventDefault()
    
    // update DOM
    this.toggleLoadingVisibility(true)

    // gather form data
    const formData = this.prepareFormData()

    // execute AJAX
    //  https://stackoverflow.com/questions/30414372/ajax-post-implementation-in-pure-javascript
    const xmlHttp = new XMLHttpRequest()
    xmlHttp.onreadystatechange = () => {
      if(xmlHttp.readyState == 4) {
        this.handleSubmitResponse(xmlHttp)
      }
    }
    xmlHttp.onerror = () => {
      this.displayFormError()
    }
    xmlHttp.open("POST", this.formElem.getAttribute('data-post-url')) 
    xmlHttp.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
    xmlHttp.send(this.toUrlEncode(formData))   

  }

  toggleLoadingVisibility(show = true) {
    if (show) {
      this.loadingElem.style.display = 'inline-block'
    } else this.loadingElem.style.display = 'none'
  }

  toggleFormVisibility(show = true) {
    if (show) {
      this.formElem.style.display = 'block'
    } else this.formElem.style.display = 'none'
  }

  handleSubmitResponse(xmlHttp) {

    this.toggleLoadingVisibility(false)

    // check for error
    if (xmlHttp.status !== 200) {
      this.displayFormError()
      console.log('error submitting form', xmlHttp.responseText);
      return
    }

    const response = JSON.parse(xmlHttp.responseText)
    if( response && typeof response.status !== 'undefined' && (response.status === "SUCCESS" || response.status === "ERRORBOT") ) {
      this.toggleFormVisibility(false)
    } else {
      this.toggleLoadingVisibility(false)
    }
    
    // update success message on DOM
    const status = response.status;
		let message_class = 'success';

    // check for error
    if (status !== 'SUCCESS') {
      this.displayFormError(response['message_text'])
      return
    }

		const responseText = response['message_text'];
		this.formMessage.classList.add('elp_form_message')
    this.formMessage.classList.remove('error') // remove any existing error classes if exist
    this.formMessage.classList.add(message_class)
		this.formMessage.innerHTML = responseText

  }

  displayFormError(errorText = false) {

    const message_class = 'error'

    const responseText = (errorText) ? errorText : 'An error occured. Please try again or contact Taylor directly.';
		this.formMessage.classList.add('elp_form_message')
    this.formMessage.classList.add(message_class)
		this.formMessage.innerHTML = responseText

  }

  prepareFormData() {

    const formData = {}

    // manual elements
    formData['elp_submit'] = 'submitted';
    formData['action'] = 'email_posts_subscribers';
    
    // loop through form components
    const elements = this.formElem.querySelectorAll("input[type=email],input[type=text],input[type=hidden]")
    for (let i = 0, element; element = elements[i++];) {
      formData['elp_' + element.name] = element.value
    }

    return formData

  }

  // pure function
  toUrlEncode(object){
    var list = []
    for (const [key, value] of Object.entries(object)) {
     list.push(key+'='+encodeURIComponent(value))
    }
    return list.join('&')
  }

}