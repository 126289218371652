import 'intersection-observer'

export default class TableOfContentsNav {
  
  constructor () {

    this.activeIndex = 0

    // retreive and cache DOM references
    const tocContentContainer = document.querySelector('.table-of-contents-container.toc-left-aside')
    this.tocContentsList = tocContentContainer.querySelector('.contents-list')
    const articleNode = document.querySelector('article')

    // create click handlers for toc list items
    // ie 11 fix
    Array.prototype.slice.call(this.tocContentsList.childNodes).forEach( (listItem) => listItem.addEventListener('click', this.handleMenuItemClick.bind(this)) )

    // special click handlers for inline TOC
    // ie 11 fix
    Array.prototype.slice.call(articleNode.querySelector('.table-of-contents-container.toc-inline .contents-list').childNodes).forEach( (listItem) => listItem.addEventListener('click', this.handleMenuItemClick.bind(this)) )

    // configure intersection observer to track scroll progress throughout document
    const options = {
      root: null, // relative to document viewport 
      rootMargin: '-2px', // margin around root. Values are similar to css property. Unitless values not allowed
      threshold: 1.0 // visible amount of item shown in relation to root
    };

    // configure intersection observer got monitoring of scroll progress
    const observer = new IntersectionObserver(this.handleObserverIntersection.bind(this), options);

    // query document for header tags and perform various operations on hTags  
    this.hTags = []
    // add hTag elem for post title
    //  the span.scrolltracker is added in single-post.php (done as part of same commit; see git blame)
    //  https://dev.gritto.net/gritto/madison-blog/-/issues/89
    this.hTags.push(articleNode.querySelector('h1#postTitle'));
    // need to look under article's section#main-content node for h nodes
    //  https://dev.gritto.net/gritto/madison-blog/-/issues/89
    const mainContentNode = articleNode.querySelector('#main-content');
    // ie 11 fix
    Array.prototype.slice.call(mainContentNode.childNodes).forEach( (hTag) => {
      // omit non header tags
      if (!hTag.tagName || hTag.tagName.toUpperCase() === 'TEXT' || hTag.getElementsByTagName('img').length) return
      if (hTag.tagName && hTag.tagName.toUpperCase().indexOf('H')===0) {
        
        // add scroll tracker span inside each header tag (allows for scroll to under sticky header)
        const scrollTrackerElem = document.createElement('span')
        scrollTrackerElem.classList.add('scroll-tracker')
        // set index on scroll tracker span (used with intersection observer and callbacks)
        scrollTrackerElem.setAttribute('data-toc-idx', this.hTags.length)
        hTag.appendChild(scrollTrackerElem)

        // observe header tag with intersection observer (to determine actively viewed section)
        observer.observe(scrollTrackerElem)

        // track hTag for use later in various callbacks etc
        this.hTags.push(hTag)

      }
    })

    // track number of scroll tracker elements for intersection observer callback
    this.trackerElemCount = this.hTags.length

    // call render function to set active class
    this.render()

  }

  handleMenuItemClick(elem) {
    const clickedElemIndex = elem.target.getAttribute('data-toc-idx')
    // designates scroll to element - uses span.scroll-tracker to accurately scroll to element under sticky header nav
    const scrollToElem = this.hTags[clickedElemIndex].getElementsByTagName('span')[0]
    scrollToElem.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  handleObserverIntersection(entries) {
    let localActiveIndex = this.activeIndex
      
    // console.log('------start--------')

    const aboveIndeces = []
    const belowIndeces = []

    entries.forEach(entry => {
      // detect if intersecting element is above the top of browser viewport; include cross browser logic
      const boundingClientRectY = (typeof entry.boundingClientRect.y !== 'undefined') ? entry.boundingClientRect.y : entry.boundingClientRect.top
      const rootBoundsY = (typeof entry.rootBounds.y !== 'undefined') ? entry.rootBounds.y : entry.rootBounds.top
      const isAbove = boundingClientRectY < rootBoundsY
      
      // get index of intersecting element from DOM attribute
      const intersectingElemIdx = parseInt(entry.target.getAttribute('data-toc-idx'))
      
      // tally index as either above or below current index
      if (isAbove) aboveIndeces.push(intersectingElemIdx)
      else belowIndeces.push(intersectingElemIdx)

    })

    // determine min and max fired indeces values (support for multiple entries firing at once)
    const minIndex = Math.min.apply(Math, belowIndeces)
    const maxIndex = Math.max.apply(Math, aboveIndeces)

    // console.log('aboveIndeces', aboveIndeces)
    // console.log('belowIndeces', belowIndeces)

    // determine if significant scroll event; ignore below indeces that file and render all on screen
    let scrollingEventDown
    if (aboveIndeces.length > 0) {
      scrollingEventDown = true
      // console.log('scrolling down')
    } else if (belowIndeces.length > 0 && minIndex <= this.activeIndex) {
      scrollingEventDown = false
      // console.log('scrolling up')
    }

    // execute logic if significant scroll event occurs 
    if (typeof scrollingEventDown !== 'undefined'){

      // update new index based on scroll direction
      if (scrollingEventDown) {
        localActiveIndex = Math.max.apply(Math, aboveIndeces)
      } else {
        localActiveIndex = (minIndex - 1 >= 0) ? minIndex - 1 : 0
      }

      // render new index to DOM (if required)
      if (localActiveIndex != this.activeIndex){
        this.activeIndex = localActiveIndex
        this.render()
      }

    }

    // console.log('------end--------')

  }

  // update DOM element to feature active class per the active index
  render () {
    // console.log('render', this.activeIndex)
    const currentlyActive = this.tocContentsList.getElementsByClassName('active')
    if (currentlyActive.length > 0) currentlyActive[0].classList.remove('active')
    else this.hTags[0].classList.add('active')
    this.tocContentsList.getElementsByTagName('li')[this.activeIndex].classList.add('active')
  }
}
