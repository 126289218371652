// polyfills
import smoothscroll from 'smoothscroll-polyfill'
import "core-js/stable" // no longer provided by babel - https://babeljs.io/docs/en/babel-polyfill

// matches 
if (!Element.prototype.matches) {
  Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
}

// prism
import Prism from 'prismjs'
import "prismjs/components/prism-markup"
import "prismjs/components/prism-markup-templating"
import "prismjs/components/prism-sql"
import "prismjs/components/prism-java"
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-json"
import "prismjs/components/prism-git"
import "prismjs/components/prism-jsx"
import "prismjs/components/prism-php"
import "prismjs/components/prism-sass"
import "prismjs/components/prism-css"
import "prismjs/components/prism-apacheconf"
import "prismjs/components/prism-bash"
import "prismjs/plugins/line-numbers/prism-line-numbers"
Prism.highlightAll();

// components
import HeaderLogoResizer from './components/header-logo-resizer'
import TableOfContentsNav from './components/post-table-of-contents'
import LeadCaptureForm from './components/lead-capture-form'

let Main = {
  init: function () {
    
    // activate polyfills
    smoothscroll.polyfill()

    // initialize app and components
    document.addEventListener('DOMContentLoaded', () => {

      // header logo resizer - added 9/23/2019
      this.headerLogoResizer = new HeaderLogoResizer()

      // table of contents
      if (document.querySelectorAll('.table-of-contents-container').length)
        this.tableOfContentsNav = new TableOfContentsNav()

      // lead capture
      if (document.querySelector('#lead-capture-form'))
        this.leadCaptureForm = new LeadCaptureForm()

      // post comments
      // if (document.querySelectorAll('#comments').length) 
      //  this.commentsForm = new CommentForm()

      // search
      // if (document.getElementById('search-overlay'))
      //  this.search = new Search()

      console.log('js v1.6.0 ie11-compat initialized')

    })

  }
}
Main.init()
